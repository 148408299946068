import { Visibility, VisibilityOff, CheckCircle, Error } from '@mui/icons-material';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { apiUrl, passportUrl } from '@/app/env/env.config';
import { ReactComponent as mainLogo } from '@/shared/assets/images/mainLogo.svg';
import { PreLoader } from '@/shared/ui/PreLoader/PreLoader';


const ChangePasswordModal = ({ setShowChangePasswordModal }) => {
  const [ userPassword, setUserPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ showPassword, setShowPassword ] = useState(false);
  const [ isPasswordUpdated, setIsPasswordUpdated ] = useState(false);
  const [ status, setStatus ] = useState<number | null>(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ validations, setValidations ] = useState({
    lengthMin: false,
    lengthMax: false,
    noCyrillic: false,
    capitalLetter: false,
    smallLetter: false,
    number: false,
    noSpaces: false,
    specialChar: false,
    match: false,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get('userId');
  const code = queryParams.get('code');

  const navigate = useNavigate();

  useEffect(() => {
    const validationObject = {
      lengthMin: userPassword.length >= 6,
      lengthMax: userPassword.length <= 128,
      noCyrillic: !/[а-яА-ЯёЁ]/.test(userPassword),
      capitalLetter: /[A-Z]/.test(userPassword),
      smallLetter: /[a-z]/.test(userPassword),
      number: /\d/.test(userPassword),
      noSpaces: !/\s/.test(userPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(userPassword),
      match: userPassword === confirmPassword,
    };
    setValidations(validationObject);
  }, [ userPassword, confirmPassword ]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const isPasswordValid = () =>
    Object.values(validations).every((validation) => validation);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isPasswordValid()) {
      try {
        await axios.put(`${apiUrl}user/sso/password/reset/confirm`, { userId, code, newPassword: userPassword }, { headers: { 'Content-Type': 'application/json', }, });
        setIsPasswordUpdated(true);
        toast.success('Пароль успешно изменен!');
      } catch (error) {
        setStatus(null);
        toast.error('Не удалось изменить пароль. Попробуйте позже.');
      }
    }
  };

  const handleNavigateToProfile= () => {
    window.location.href = `${passportUrl}/navigator`;
  };

  const passwordRequirements = [
    { name: 'Не менее 6 символов', condition: validations.lengthMin },
    { name: 'Допустимо не более 128 символов', condition: validations.lengthMax },
    { name: 'Пароль не может содержать кириллицу', condition: validations.noCyrillic },
    { name: 'Как минимум одна заглавная буква', condition: validations.capitalLetter },
    { name: 'Как минимум одна строчная буква', condition: validations.smallLetter },
    { name: 'Как минимум одна цифра', condition: validations.number },
    { name: 'Как минимум один специальный символ', condition: validations.specialChar },
    { name: 'Без пробелов', condition: validations.noSpaces },
    { name: 'Пароли совпадают', condition: validations.match },
  ];

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        setIsLoading(true);
        if (userId && code) {
          const response = await axios.put(`${apiUrl}user/sso/password/reset/status`, { userId, code }, { headers: { 'Content-Type': 'application/json', }, });
          setStatus(response.data.data.statusId);
        } else {
          console.error('Ошибка при проверке статуса:');
        }
      } catch (error) {
        console.error('Ошибка при проверке статуса:', error);
        setStatus(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (userId && code) {
      fetchStatus();
    }
  }, [ userId, code ]);

  useEffect(() => {
    setShowChangePasswordModal(false);

    return () => setShowChangePasswordModal(true);
  }, []);

  if (isLoading) {
    return (
      <PreLoader />
    );
  }

  return (
    <Modal open={true} sx={{ outline: 'none' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          outline: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            mb: 1,
          }}
        >
          <SvgIcon component={mainLogo} inheritViewBox sx={{ fontSize: 60 }} />
        </Box>
        {status === 1 && !isPasswordUpdated && (
          <>
            <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                  Смена пароля
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="new-password">Введите новый пароль</InputLabel>
                <OutlinedInput
                  id="new-password"
                  type={showPassword ? 'text' : 'password'}
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Введите новый пароль"
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="confirm-password">Подтвердите пароль</InputLabel>
                <OutlinedInput
                  id="confirm-password"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Подтвердите пароль"
                />
              </FormControl>
              <List dense>
                {passwordRequirements.map((requirement, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      {requirement.condition ? (
                        <CheckCircle sx={{ color: '#5868B4' }} />
                      ) : (
                        <Error sx={{ color: 'grey' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={requirement.name} />
                  </ListItem>
                ))}
              </List>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: '#5868B4',
                  '&:hover': { backgroundColor: '#4A5A9C' },
                }}
                disabled={!isPasswordValid()}
              >
                    Изменить пароль
              </Button>
            </form>
          </>
        )}
        {status === 1 && isPasswordUpdated && (
          <>
            <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                  Пароль успешно изменён!
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={handleNavigateToProfile}
              sx={{
                backgroundColor: '#5868B4',
                '&:hover': { backgroundColor: '#4A5A9C' },
              }}
            >
                  Перейти в профиль
            </Button>
          </>
        )}
        {status === 2 && (
          <>
            <Typography variant="h6" component="h2" sx={{ textAlign: 'center', }}>
                  Ссылка для сброса пароля истекла.
            </Typography>
          </>
        )}
        {status === 3 && (
          <>
            <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                  Пароль уже был изменён.
            </Typography>
          </>
        )}
        {status === null && (
          <Typography variant="h6" sx={{ textAlign: 'center', color: 'red' }}>
                Произошла ошибка. Пожалуйста, попробуйте позже.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
